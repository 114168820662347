<template>
  <div class="product-page">
    <div class="d-flex align-center">
      <h1>Orders</h1>
      <v-spacer></v-spacer>
      <!-- <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn> -->
      <!-- <v-btn outlined class="ml-2" color="primary"
          ><i class="fas fa-download"></i>{{ $t('Import tracking number') }}
        </v-btn> -->
      <v-btn
        v-if="utilities.checkPermisson(`export_orders`, user)"
        color="primary"
        @click="modalExport = true"
        depressed
      >
        <i class="ri-upload-line mr-1"></i>
        Export
      </v-btn>
    </div>
    <Orders />
    <modal width="600px" model="modalExport" title="Export orders" :callback="submitExportCSV">
      <template slot="content">
        <div>
          <label class="label-form">Store</label>
          <v-select
            v-model="selectedStores"
            :items="stores"
            multiple
            chips
            item-value="id"
            item-text="storeDetail.defaultSystemDomain"
            clearable
            outlined
            dense
          >
          </v-select>
          <label class="label-form">Export</label>
          <v-radio-group v-model="radioGroup" dense class="mt-1">
            <v-radio @click="selectAll" value="all-products" label="All orders"></v-radio>
            <v-radio @click="selectCurent" value="curent-search" label="Current search"></v-radio>
          </v-radio-group>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utilities from '@/helpers/utilities';
import { storeService } from '@/apis/store.s';
import Orders from './components/Orders';
import { orderApi } from '@/apis/order';
export default {
  components: { Orders },
  data() {
    return {
      stores: null,
      selectedStores: [],
      utilities,
      radioGroup: '',
      searchOrder: '',
      params: {
        page: this.page,
        limit: this.limit,
      },
      checkCurentSearch: true,
      value: [],
      modalExport: false,
      items: ['1', '2', '3'],
    };
  },
  watch: {
    searchOrder() {
      console.log(this.searchOrder);
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.getStores();
  },
  methods: {
    async getStores() {
      try {
        let response = await storeService.list({
          limit: 100,
        });
        this.stores = response.data.filter(item => item !== null);
        this.stores = this.stores.map(function(store) {
          return {
            ...store,
            storeName: store.storeDetail.name,
          };
        });
        // console.log('stores', this.stores);
      } catch (error) {
        console.log(error);
      }
    },
    selectAll() {
      this.params = {};
    },
    selectCurent() {
      // console.log(this.searchOrder);
      // this.params.curentSearch = this.$router.app._route.query._query;
      if (this.searchOrder != '') {
        this.params.search = this.searchOrder;
      }
      if (this.$route.query._query) {
        let queries = JSON.parse(this.$route.query._query);
        queries.forEach(q => {
          if (q.compare) {
            this.params[q.field] = q.value;
            this.params[q.fieldCompare] = q.compare;
          } else {
            this.params[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
          }
        });
      }
    },
    downloadFile(fileName, urlData) {
      var aLink = document.createElement('a');
      aLink.download = fileName;
      aLink.href = urlData;
      var event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    },
    async submitExportCSV() {
      if (this.selectedStores) {
        this.params.storeIds = this.selectedStores.join(',');
      }
      let data = await orderApi.getExport(this.params);
      this.downloadFile('export.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.data));
      this.modalExport = false;
      this.radioGroup = '';
    },
  },
};
</script>
