<template>
  <v-card class="pa-0" outlined>
    <Resource
      :selectFulfill="select"
      :document="document"
      :filter-fields="filter"
      :filter-data="filterData"
      :filter-callback="setFilterData"
      :searchable="searchableField"
      :columns="columns"
      :updateRouterCallback="getUpdateRouterData"
      @closeClicked="closeClicked"
      :routeName="updateRouteName"
      class="px-3"
    />
    <div class="d-flex justify-space-between mt-4 px-3">
      <limit-page :callback="setLimit" />
      <pagination :visible="0" :length="lengthPage" :page.sync="page"></pagination>
    </div>
  </v-card>
</template>

<script>
import Resource from '@/components/Resources';
import filter from '@/const/filters/order-filter';
import resourceListMixin from '@/mixins/resource-list';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import utilities from '@/helpers/utilities';
export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      // country,
      utilities,
      resourceType: 'orders',
      // page: 1,
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testVuex: null,
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },

        Order: {
          field: 'name',
          kind: 'viewOrderCheckRole',
        },
        'Capture Date': {
          field: 'capturedDate',
          kind: 'datetime',
        },
        Customer: {
          field: 'customerInfo',
          kind: 'customer',
        },
        'Payment Status': {
          field: 'financialStatus',
          kind: 'text',
        },
        'Payment Methods': {
          field: 'paymentName',
          kind: 'text',
        },
        'Fulfillment Status': {
          field: 'fulfillmentStatus',
          kind: 'text',
        },
        Total: {
          kind: 'price',
          field: 'total',
          // blankLabel: true,
        },
      },
      searchableField: 'title_search',
      updateRouteName: 'website.orders.detail',
      select: true,
      sortable: [],
      filter,
    };
  },

  created() {
    this.updateRouteName = !this.utilities.checkPermisson(`write_orders`, this.user)
      ? 'no-access'
      : `website.orders.detail`;
    // console.log('AAAA');
  },
  methods: {
    // clearFilter() {
    //   this.filter.forEach(i => {
    //     i.model = '';
    //   });
    // },
    closeClicked(data) {
      this.filter.forEach(item => {
        if (item.field == data && item.type.name != 'order-name') {
          item.model = '';
        }
        if (item.type.name == 'order-name') {
          item.model[0] = '';
          item.model[1] = '';
        }
      });
    },
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
      ...mapGetters(['user']),
    }),
  },
  watch: {
    clear: function() {
      if (this.clear == true) {
        this.filter.forEach(i => {
          i.model = '';
        });
      }
      console.log(this.clear);
    },
    // $route: function() {
    //   console.log(this.$route);
    // },
    searchString() {
      console.log(this.searchString);
      this.$parent.searchOrder = this.searchString;
    },
  },
};
</script>
